<template>
  <b-card-actions
    ref="cardAction"
    title="Ürünleri Sırala"
    icon="ShoppingCartIcon"
    :showLoading="show"
    :actionCollapse="false"
    :actionRefresh="true"
    :actionNewAdd="false"
    :collapsed="true"
    @refresh="refreshStop('cardAction')"
    @newAdd="handlerNewAdd($event)"
  >
    <template v-slot:body>
      {{ show }}
      <draggable v-model="urunler" handle=".draggable-task-handle" @end="handlerResimSiraChange">
        <transition-group type="transition" name="flip-list" class="row" tag="div">
          <b-col cols="12" md="4" v-for="(urun, i) in urunler" :key="urun.k_no">
            <b-card class="draggable-task-handle">
              <v-card-text>
                <div class="d-flex">
                  <strong class="text-primary">{{ i + 1 }} </strong>
                  <p class="pl-1">{{ urun.icerik['tr'].urun_adi | str_limit(40) }}</p>
                </div>
              </v-card-text>
            </b-card>
          </b-col>
        </transition-group>
      </draggable>
    </template>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import draggable from 'vuedraggable';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import store from '@/store';
export default defineComponent({
  components: {
    draggable,
    BCardActions,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);

    expo.urunler = ref([]);
    expo.posts = ref([]);

    onMounted(async () => {
      expo.show.value = true;
      store.dispatch('urunSiraListele').then((res) => {
        if (res.data.success) expo.urunler.value = res.data.data;

        expo.show.value = false;
      });
      // expo.urunler.value = store.getters.getUrunler;
    });

    expo.handlerResimSiraChange = async () => {
      expo.show.value = true;
      expo.urunler.value.map((item, i) => {
        let sira = i + 1;
        expo.posts.value.push({
          k_no: item.k_no,
          sira,
        });
      });

      console.log(expo.posts.value.length);

      await store.dispatch('urunSiraGuncelle', { urunler: expo.posts.value }).then((res) => {
        if (res.data.success === true) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Güncelleme Başarılı',
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
        }
        expo.show.value = false;
      });
    };

    return { ...expo };
  },
});
</script>

<!-- <script>
export default {
  computed: {
    ...mapGetters(['getUrunler']),
  },
  data: () => ({}),
  created() {
    expo.handlerUrunler();
  },
  methods: {},
};
</script> -->

<style lang="scss" scoped>
.draggable-task-handle {
  cursor: move;
}
</style>
